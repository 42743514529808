import { authStore } from '@/shared/pinia-store/auth';
import { defineStore, storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { toast } from '../native/toast';
import { newTextKey } from './storyworld';
import { WorldsInfo, Image, ImageCollection, KeyedColor } from '@/shared/types/static-types';

import { worldAboutKeys, unreorderableCreateWorldKeys } from '@/shared/statics/constants';
import {
  deleteWorld,
  deleteWorldDraft,
  getStoryWorldDraft,
  getWorld,
  editStoryWorldDraft,
  editStoryWorldDraftAuto,
  createSaveDraft,
  editWorld,
  editWorldAuto,
  createWorld,
} from '@/shared/actions/worlds';
import { reorderImageCollectionImages, getVisualCollections } from '@/shared/actions/imagesCollections';
import { imageStore } from '@/shared/pinia-store/images';
import logger from '@/shared/services/logger';
import { clearWorldCacheKeys } from '../utils/cahce';

export interface IStoryworldCreatorState {
  currentStoryWorld: WorldsInfo | null | any;
  isLoading: boolean;
  currentImageCollections: ImageCollection[];
  currentImageCollectionsChanged: boolean;
  keyedColors?: KeyedColor[];
  _autosaveEnabled: boolean;
  isDropDownCollapsed: boolean;
  isDirty: boolean;
  isSaving: boolean;
  isDraft: boolean;
  isDone: boolean;
  autosaveFailures: number;
}

export const useStoryWorld = defineStore('storyworld-creator', {
  state: (): IStoryworldCreatorState => ({
    currentStoryWorld: null,
    isLoading: false,
    currentImageCollections: [],
    currentImageCollectionsChanged: false,
    keyedColors: [],
    _autosaveEnabled: false,
    isSaving: false,
    isDirty: false,
    isDraft: false,
    isDone: false,
    autosaveFailures: 0,
    isDropDownCollapsed: true,
  }),
  actions: {
    markAsDirty() {
      this.isDirty = true;
    },
    markIsDone() {
      this.isDone = true;
    },
    setAutosaveEnabled(val: boolean) {
      this._autosaveEnabled = val;
    },
    setIsDraft(isDraft: boolean) {
      this.isDraft = isDraft;
    },
    async fetchOwnVisualCollections(id: string, route: string) {
      try {
        let response: any;

        if (route === 'draft-story-world') {
          response = await getVisualCollections({ page: 1, ownerType: 'world_draft', ownerId: id as string });
        } else {
          response = await getVisualCollections({ page: 1, ownerType: 'world', ownerId: id as string });
        }
        this.currentStoryWorld.collections = response.results;
      } catch (_e) {}
    },
    async loadEditor(id: string, route: string) {
      this.clearAll();
      let errorEncountered = false;
      try {
        this.isLoading = true;
        if (!id) {
          this.initNewStoryworld();
          this.isLoading = false;
          this.isDirty = false;
          this.isSaving = false;
          this.isDone = false;
          this.isDraft = true;
          this._autosaveEnabled = true;
          this.autosaveFailures = 0;
          return;
        }

        let worldInstance: WorldsInfo;
        if (route === 'draft-story-world') {
          this.isDraft = true;
          worldInstance = await getStoryWorldDraft(id);
          this.fetchOwnVisualCollections(id, route);
        } else {
          this.isDraft = false;
          worldInstance = await getWorld(id);
          worldInstance.subworlds = worldInstance.subworlds.map((world: any) => world.id);
          this.fetchOwnVisualCollections(id, route);
        }

        const { user: authUser } = authStore();

        const username = worldInstance.author?.username || worldInstance.user?.username;

        if (username !== authUser.value.username) {
          const router = useRouter();
          return router.replace('/');
        }

        this.currentStoryWorld = worldInstance;
        if (!this.currentStoryWorld.privacy) this.currentStoryWorld.privacy = 'P';
        this.afterStoryWorldLoad(); // not an async function
      } catch (error) {
        logger.error({
          e: error,
          loc: 'storyworld-creator.loadEditor',
          msg: 'storyworld-creator loadEditor: ' + error,
          data: {
            currentWorld: this.currentStoryWorld,
            route,
            id,
            isDraft: this.isDraft,
          },
        });
        toast.show('Error loading world', 'nonative', 'danger');
        errorEncountered = true;
      } finally {
        this.isLoading = false;
        this.isDirty = false;
        this.isSaving = false;
        this.isDone = false;
      }
      if (errorEncountered) this.setAutosaveEnabled(false);
      else if (this.isDraft && this.currentStoryWorld?.id) this.setAutosaveEnabled(true);
      else if (['U', 'M'].includes(this.currentStoryWorld?.privacy || '')) this.setAutosaveEnabled(true);
      else this.setAutosaveEnabled(false);
    },
    initNewStoryworld() {
      this.currentStoryWorld = {
        name: '',
        description: '',
        cover_image: '',
        is_nsfw: false,
        allow_join: false,
        character_worlds: [],
        featured_collections: [],
        subworlds: [],
        included_locations: [],
        membership_details: '',
        tags: [],
        privacy: 'P',
        visual_collections_page: {},
        extra: {},
        about: [
          { key: 'climate', value: '' },
          { key: 'religion', value: '' },
          { key: 'economy', value: '' },
          { key: 'clothesAndFashion', value: '' },
        ],
      };
      const extraPayload = {
        ...this.currentStoryWorld.extra,
        about: this.currentStoryWorld.about,
      };
      this.currentStoryWorld.extra = extraPayload;
      const order: string[] = this.currentStoryWorld?.extra.order || Object.keys(this.currentStoryWorld);
      this.currentStoryWorld.extra.order = order.filter((key: string) => !unreorderableCreateWorldKeys.includes(key));
      this.isDirty = false;
      this.isSaving = false;
      this._autosaveEnabled = false;
    },
    async autosaveDraft() {
      if (!this.autosaveEnabled) return;
      if (this.isSaving) return;
      // if editing an existing draft, run this:
      this.isSaving = true;
      try {
        const imgPayload = {} as any;
        if (this.currentStoryWorld.id) {
          imgPayload.id = this.currentStoryWorld.id;
          imgPayload.model = 'world_draft';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const { commitAllChanges } = imageStore();
        const visualCollectionIds = await commitAllChanges(imgPayload);

        // if (this.currentStoryWorld.extra.about) {
        //   const about = this.currentStoryWorld.extra.about.filter((x: any) => x.key !== '');
        //   this.currentStoryWorld.extra.about = about;
        // }
        const characterIds = this.currentStoryWorld.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const folderIds = this.currentStoryWorld.featured_collections?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const payload = {
          name: this.currentStoryWorld.name,
          description: this.currentStoryWorld.description,
          cover_image: this.currentStoryWorld.cover_image,
          //  spotify_link: this.currentStoryWorld.spotify_link,
          membership_details: this.currentStoryWorld.membership_details,
          is_nsfw: this.currentStoryWorld.is_nsfw,
          subworlds: this.currentStoryWorld.subworlds,
          characters: characterIds,
          visual_collections: visualCollectionIds,
          tags: this.currentStoryWorld.tags,
          type: 'story',
          extra: this.currentStoryWorld.extra,
          privacy: this.currentStoryWorld.privacy,
          world_collections: this.currentStoryWorld.world_collections,
          featured_collections: folderIds,
          triggerwarning: this.currentStoryWorld.triggerwarning,
          id: this.currentStoryWorld.id,
          modified: this.currentStoryWorld?.modified,
        };
        const response = await editStoryWorldDraftAuto(this.currentStoryWorld.id, payload);
        if (!this.currentStoryWorld.name) this.currentStoryWorld.name = response.name;
        this.currentStoryWorld.modified = response.modified;
        this.currentStoryWorld.id = response.id;
        this.isDirty = false;
        return response;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This world has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.setAutosaveEnabled(false);
        } else {
          logger.error({
            e,
            loc: 'storyworld-creator.autosaveDraft',
            msg: 'storyworld-creator AutosaveDraft: ' + e,
            data: {
              world: this.currentStoryWorld,
            },
          });
          toast.show('Autosave failed, trying again...', 'nonative', 'danger');
          this.autosaveFailures += 1;
          if (this.autosaveFailures > 3) {
            this.setAutosaveEnabled(false);
            toast.show('Autosave failed, pausing autosave...', 'nonative', 'danger');
          } else {
            toast.show('Autosave failed, trying again...', 'nonative', 'danger');
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    async autosaveDraftNew() {
      if (!this.autosaveEnabled) return;
      if (this.isSaving) return;
      if (this.currentStoryWorld.id) return;
      this.isSaving = true;
      try {
        const imgPayload = {} as any;
        if (this.currentStoryWorld.id) {
          imgPayload.id = this.currentStoryWorld.id;
          imgPayload.model = 'world_draft';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const { commitAllChanges } = imageStore();
        const visualCollectionIds = await commitAllChanges(imgPayload);

        // if (this.currentStoryWorld.extra.about) {
        //   const about = this.currentStoryWorld.extra.about.filter((x: any) => x.key !== '');
        //   this.currentStoryWorld.extra.about = about;
        // }
        const characterIds = this.currentStoryWorld.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const folderIds = this.currentStoryWorld.featured_collections?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const payload = {
          name: this.currentStoryWorld.name || 'Untitled',
          description: this.currentStoryWorld.description,
          cover_image: this.currentStoryWorld.cover_image,
          //   spotify_link: this.currentStoryWorld.spotify_link,
          membership_details: this.currentStoryWorld.membership_details,
          is_nsfw: this.currentStoryWorld.is_nsfw,
          characters: characterIds,
          subworlds: this.currentStoryWorld.subworlds,
          visual_collections: visualCollectionIds,
          tags: this.currentStoryWorld.tags,
          type: 'story',
          extra: this.currentStoryWorld.extra,
          privacy: this.currentStoryWorld.privacy,
          world_collections: this.currentStoryWorld.world_collections,
          featured_collections: folderIds,
          triggerwarning: this.currentStoryWorld.triggerwarning,
          modified: this.currentStoryWorld.modified,
        };
        const response = await createSaveDraft(payload);
        if (!this.currentStoryWorld.name) this.currentStoryWorld.name = response.name;
        this.currentStoryWorld.modified = response.modified;
        this.currentStoryWorld.id = response.id;
        toast.show('Story world saved as draft', 'nonative', 'primary');
        this.isDirty = false;
        history.replaceState({}, '', '/story-worlds/create/draft/' + response.id);
        return response;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This world has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.setAutosaveEnabled(false);
        } else {
          logger.error({
            e,
            loc: 'storyworld-creator.autosaveDraftNew',
            msg: 'storyworld-creator AutosaveDraftNew: ' + e,
            data: {
              world: this.currentStoryWorld,
            },
          });
          this.autosaveFailures += 1;
          if (this.autosaveFailures > 3) {
            this.setAutosaveEnabled(false);
            toast.show('Autosave failed, pausing autosave...', 'nonative', 'danger');
          } else {
            toast.show('Autosave failed, trying again...', 'nonative', 'danger');
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    async saveAsDraft() {
      if (this.isSaving) return;
      // when "Save as Draft" is pressed
      try {
        let response;
        this.isSaving = true;
        const router = useRouter();
        const { commitAllChanges } = imageStore();
        // if editing an existing draft, run this:
        const imgPayload = {} as any;
        if (this.currentStoryWorld.id) {
          imgPayload.id = this.currentStoryWorld.id;
          imgPayload.model = 'world_draft';
        } else {
          /* initial imgPayload is empty object if creating new world */
        }
        const visualCollectionIds = await commitAllChanges(imgPayload);

        // if (this.currentStoryWorld.extra.about) {
        //   const about = this.currentStoryWorld.extra.about.filter((x: any) => x.key !== '');
        //   this.currentStoryWorld.extra.about = about;
        // }
        const characterIds = this.currentStoryWorld.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const folderIds = this.currentStoryWorld.featured_collections?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        if (this.currentStoryWorld.id) {
          const payload = {
            name: this.currentStoryWorld.name,
            description: this.currentStoryWorld.description,
            cover_image: this.currentStoryWorld.cover_image,
            //   spotify_link: this.currentStoryWorld.spotify_link,
            membership_details: this.currentStoryWorld.membership_details,
            is_nsfw: this.currentStoryWorld.is_nsfw,
            subworlds: this.currentStoryWorld.subworlds,
            characters: characterIds,
            featured_collections: folderIds,
            visual_collections: visualCollectionIds,
            tags: this.currentStoryWorld.tags,
            type: 'story',
            extra: this.currentStoryWorld.extra,
            privacy: this.currentStoryWorld.privacy,
            world_collections: this.currentStoryWorld.world_collections,
            triggerwarning: this.currentStoryWorld.triggerwarning,
          };

          response = await editStoryWorldDraft(this.currentStoryWorld.id, payload);
        } else {
          const payload = {
            name: this.currentStoryWorld.name,
            description: this.currentStoryWorld.description,
            cover_image: this.currentStoryWorld.cover_image,
            //     spotify_link: this.currentStoryWorld.spotify_link,
            membership_details: this.currentStoryWorld.membership_details,
            is_nsfw: this.currentStoryWorld.is_nsfw,
            characters: characterIds,
            subworlds: this.currentStoryWorld.subworlds,
            visual_collections: visualCollectionIds,
            tags: this.currentStoryWorld.tags,
            type: 'story',
            extra: this.currentStoryWorld.extra,
            privacy: this.currentStoryWorld.privacy,
            world_collections: this.currentStoryWorld.world_collections,
            triggerwarning: this.currentStoryWorld.triggerwarning,
            id: this.currentStoryWorld.id,
          };
          response = await createSaveDraft(payload);
        }
        this.clearAll();
        router.push({ name: 'world-draft-details', params: { id: response.id } });
      } catch (e) {
        this.setAutosaveEnabled(false);
        toast.show('Encountered an error while saving draft.', 'nonative', 'danger');
      } finally {
        this.isSaving = false;
      }
    },
    async autosaveWorld() {
      if (!this.autosaveEnabled) return;
      if (this.isSaving) return;
      this.isSaving = true;
      const { commitAllChanges } = imageStore();
      const imgPayload = {} as any;
      try {
        imgPayload.id = this.currentStoryWorld.id;
        imgPayload.model = 'world';
        const visualCollectionIds = await commitAllChanges(imgPayload);
        // if (this.currentStoryWorld.extra.about) {
        //   const about = this.currentStoryWorld!.extra.about.filter((x: any) => x.key !== '');
        //   this.currentStoryWorld!.extra.about = about;
        // }
        const oldPrivacy = this.currentStoryWorld.privacy;
        const characterIds = this.currentStoryWorld.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const folderIds = this.currentStoryWorld.featured_collections?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const payload = {
          name: this.currentStoryWorld.name,
          description: this.currentStoryWorld.description,
          cover_image: this.currentStoryWorld.cover_image,
          //    spotify_link: this.currentStoryWorld.spotify_link,
          membership_details: this.currentStoryWorld.membership_details,
          is_nsfw: this.currentStoryWorld.is_nsfw,
          subworlds: this.currentStoryWorld.subworlds,
          characters: characterIds,
          visual_collections: visualCollectionIds,
          tags: this.currentStoryWorld.tags,
          type: 'story',
          extra: this.currentStoryWorld.extra,
          privacy: this.currentStoryWorld.privacy,
          world_collections: this.currentStoryWorld.world_collections,
          featured_collections: folderIds,
          triggerwarning: this.currentStoryWorld.triggerwarning,
          id: this.currentStoryWorld.id,
          modified: this.currentStoryWorld.modified,
        };
        const response = await editWorldAuto(this.currentStoryWorld.id, payload);
        const subworldIds = response.subworlds.map((i: any) => i.id);
        this.currentStoryWorld = { ...response, privacy: oldPrivacy, subworlds: subworldIds };
        this.isDirty = false;
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data.err_code === 'cannot_update') {
          this.setAutosaveEnabled(false);
          toast.show('You cannot publish NSFW content.', 'nonative', 'danger');
          return;
        } else if (e.response?.status === 400 && e.response?.data === 'Outdated') {
          alert("This world has been edited since you've last been here! Fetching info...");
          window.location.reload();
          this.setAutosaveEnabled(false);
        } else {
          logger.error({
            e,
            loc: 'storyworld-creator.autosaveWorld',
            msg: 'storyworld-creator AutosaveWorld: ' + e,
            data: {
              world: this.currentStoryWorld,
            },
          });
          this.autosaveFailures += 1;
          if (this.autosaveFailures > 3) {
            this.setAutosaveEnabled(false);
            toast.show('Autosave failed, pausing autosave...', 'nonative', 'danger');
          } else {
            toast.show('Autosave failed, trying again...', 'nonative', 'danger');
          }
        }
      } finally {
        this.isSaving = false;
      }
    },
    async publish() {
      if (this.isSaving) return;
      try {
        this.isSaving = true;
        let response;
        clearWorldCacheKeys();
        const router = useRouter();
        const route = router.currentRoute.value;
        const { commitAllChanges } = imageStore();
        const imgPayload = {} as any;
        if (this.currentStoryWorld.id) {
          imgPayload.id = this.currentStoryWorld.id;
          imgPayload.model = 'world';
        }
        const visualCollectionIds = await commitAllChanges(imgPayload);
        // if (this.currentStoryWorld.extra.about) {
        //   const about = this.currentStoryWorld!.extra.about.filter((x: any) => x.key !== '');
        //   this.currentStoryWorld!.extra.about = about;
        // }
        const characterIds = this.currentStoryWorld.character_worlds?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        const folderIds = this.currentStoryWorld.featured_collections?.map((c: any) =>
          typeof c === 'string' ? c : c.id
        );
        if (route.name === 'draft-story-world' || route.name === 'create-story-world') {
          const payload = {
            name: this.currentStoryWorld.name,
            description: this.currentStoryWorld.description,
            cover_image: this.currentStoryWorld.cover_image,
            // spotify_link: this.currentStoryWorld.spotify_link,
            membership_details: this.currentStoryWorld.membership_details,
            is_nsfw: this.currentStoryWorld.is_nsfw,
            subworlds: this.currentStoryWorld.subworlds,
            characters: characterIds,
            featured_collections: folderIds,
            visual_collections: visualCollectionIds,
            tags: this.currentStoryWorld.tags,
            type: 'story',
            extra: this.currentStoryWorld.extra,
            privacy: this.currentStoryWorld.privacy,

            world_collections: this.currentStoryWorld.world_collections,
            triggerwarning: this.currentStoryWorld.triggerwarning,
            modified: this.currentStoryWorld.modified,
          } as any;
          if (route.name === 'draft-story-world' && this.currentStoryWorld.id) {
            payload.draft_id = this.currentStoryWorld!.id;
          }
          response = await createWorld(payload);
          const router = useRouter();
          router.push({ name: 'world-details', params: { slug: response.slug } });
          this.clearAll();
        } else {
          const payload = {
            name: this.currentStoryWorld.name,
            description: this.currentStoryWorld.description,
            cover_image: this.currentStoryWorld.cover_image,
            //     spotify_link: this.currentStoryWorld.spotify_link,
            membership_details: this.currentStoryWorld.membership_details,
            is_nsfw: this.currentStoryWorld.is_nsfw,
            subworlds: this.currentStoryWorld.subworlds,
            characters: characterIds,
            visual_collections: visualCollectionIds,
            tags: this.currentStoryWorld.tags,
            type: 'story',
            extra: this.currentStoryWorld.extra,
            privacy: this.currentStoryWorld.privacy,
            featured_collections: folderIds,
            world_collections: this.currentStoryWorld.world_collections,
            triggerwarning: this.currentStoryWorld.triggerwarning,
            id: this.currentStoryWorld.id,
            modified: this.currentStoryWorld.modified,
          };
          response = await editWorld(this.currentStoryWorld.id, payload);
          const router = useRouter();
          router.push({ name: 'world-details', params: { slug: response.slug } });
          this.clearAll();
        }
      } catch (e: any) {
        if (e.response?.status === 400 && e.response?.data.err_code === 'cannot_update') {
          this.setAutosaveEnabled(false);
          throw e;
        } else {
          toast.show('Encountered an error while saving.', 'nonative', 'danger');
          this.setAutosaveEnabled(false);
          logger.error({
            e,
            loc: 'storyworld-creator.publish',
            msg: 'storyworld-creator Publish: ' + e,
            data: {
              world: this.currentStoryWorld,
            },
          });
        }
      } finally {
        this.isSaving = false;
      }
    },
    collapseDropDown(value: any) {
      this.isDropDownCollapsed = value;
    },
    changeCover(url: string) {
      if (!this.currentStoryWorld) return;
      this.currentStoryWorld.cover_image = url;
      this.isDirty = true;
    },
    clear() {
      const { reset: resetImageStore } = imageStore();
      this.currentStoryWorld = null;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.keyedColors = [];
      this.autosaveFailures = 0;
      resetImageStore();
    },
    clearAll() {
      const { reset: resetImageStore } = imageStore();
      this.currentStoryWorld = null;
      this.isLoading = false;
      this.currentImageCollections = [];
      this.currentImageCollectionsChanged = false;
      this.keyedColors = [];
      this.isSaving = false;
      this.isDirty = false;
      this._autosaveEnabled = false;
      this.autosaveFailures = 0;
      resetImageStore();
    },
    changeInfoKey(key: any, value: any) {
      if (!this.currentStoryWorld) return;
      const newextraKey = ['about', 'colors', 'featuredin', 'quote'];
      if (newextraKey.includes(key)) {
        if (!get(this.currentStoryWorld, 'extra')) return;
        this.isDirty = true;
        this.currentStoryWorld.extra[key] = value;
        return;
      }
      this.currentStoryWorld[key] = value;
      this.isDirty = true;
    },
    changeExtraKey(key: any, value: any) {
      if (!this.currentStoryWorld) return;
      this.currentStoryWorld.extra[key] = value;
      this.isDirty = true;
    },
    afterStoryWorldLoad() {
      const { setImageCollections } = imageStore();
      if (this.currentStoryWorld.extra.order) {
        const order: string[] = this.currentStoryWorld?.extra.order || Object.keys(this.currentStoryWorld);
        this.currentStoryWorld.extra.order = order.filter((key: string) => !unreorderableCreateWorldKeys.includes(key));
      } else {
        this.currentStoryWorld.extra.order = Object.keys(this.currentStoryWorld).filter(
          (key: string) => !unreorderableCreateWorldKeys.includes(key)
        );

        const index = this.currentStoryWorld.extra.order.indexOf('description');
        if (index > -1)
          this.currentStoryWorld.extra.order.unshift(this.currentStoryWorld.extra.order.splice(index, 1)[0]);
      }
      if (this.currentStoryWorld.visual_collections_page) {
        this.currentImageCollections = this.currentStoryWorld.visual_collections_page.results as ImageCollection[];
      }

      if (this.currentStoryWorld.extra.colors) {
        this.keyedColors = this.currentStoryWorld.extra.colors.map((color: any) => {
          return { id: nanoid(6), color };
        });
      }
      setImageCollections(this.currentImageCollections);
    },
    addSection(key: string) {
      const extraKey = ['featuredin'];
      const extraArrKey = ['colors', 'about', 'spotify_link'];
      const objKeys = ['visual_collections_page'];
      const arrKeys = ['character_worlds', 'subworlds', 'featured_collections'];
      const normalTextKeys = ['description'];
      if (!this.currentStoryWorld) return;

      let _newTextKey = '';

      if (key === 'text') {
        _newTextKey = newTextKey(this.currentStoryWorld.extra);

        this.currentStoryWorld.extra[_newTextKey] = {
          type: 'text',
          body: '',
          title: '',
        };
      }
      if (extraKey.includes(key)) {
        this.currentStoryWorld.extra[key] = {};
      }
      if (extraArrKey.includes(key)) {
        this.currentStoryWorld.extra[key] = [];
      }

      if (objKeys.includes(key)) {
        this.currentStoryWorld[key] = {};
      }

      if (arrKeys.includes(key)) {
        this.currentStoryWorld[key] = [];
      }

      if (normalTextKeys.includes(key)) {
        this.currentStoryWorld[key] = '';
      }

      const usedKey = key === 'text' ? _newTextKey : key;
      this.currentStoryWorld.extra.order = [...(this.currentStoryWorld.extra.order || []), usedKey];
    },
    removeSection(key: string) {
      if (!this.currentStoryWorld) return;
      this.currentStoryWorld = omit({ ...this.currentStoryWorld }, key);
      if (this.currentStoryWorld.extra[key]) {
        this.currentStoryWorld.extra = omit({ ...this.currentStoryWorld.extra }, key);
      }
      if (this.currentStoryWorld?.extra.order?.length) {
        this.currentStoryWorld.extra.order = this.currentStoryWorld.extra.order.filter((k: string) => k !== key);
      }
      this.isDirty = true;
    },
    changeLocalImageCollections(collections: any[]) {
      if (!this.currentStoryWorld) return;
      this.currentStoryWorld.visual_collections = collections;
      this.currentImageCollections = collections;
      this.currentImageCollectionsChanged = true;
      this.currentStoryWorld.collections = collections;
      this.isDirty = true;
    },
    changeLocalImageCollectionImageOrder(collectionId: string, images: Image[]) {
      if (!this.currentStoryWorld) return;
      const collection = this.currentImageCollections.find((c: any) => c.id === collectionId);
      if (!collection) return;
      collection.images_page!.results = images;
      reorderImageCollectionImages(collectionId, images.map((i: Image) => i.id).filter(Boolean) as string[]);
      this.isDirty = true;
    },
    setWorldColorsEditable(colorObjs: KeyedColor[]) {
      // { color: '#000000', id: 'fjqpek' }
      if (!this.currentStoryWorld) return;
      this.keyedColors = colorObjs;
      const toStore = colorObjs.map((x) => x.color);
      this.currentStoryWorld.extra.colors = toStore;
      this.isDirty = true;
    },
    setWorldAbout(aboutRows: any[]) {
      if (!this.currentStoryWorld) return;
      this.currentStoryWorld.extra.about = aboutRows;
      this.isDirty = true;
    },
    async deleteWorld() {
      try {
        await deleteWorld(this.currentStoryWorld.id);
        const router = useRouter();
        router.replace({ name: 'worlds-tabs', query: { tab: 'myworlds' } });
        toast.show('Story world deleted', 'nonative', 'primary');
        this.clearAll();
      } catch (e: any) {
        toast.show('Encountered an error while deleting world.', 'nonative', 'danger');
      } finally {
        this.setAutosaveEnabled(false);
      }
    },
    async deleteWorldDraft() {
      try {
        await deleteWorldDraft(this.currentStoryWorld.id);
        const router = useRouter();
        router.replace({ name: 'worlds-tabs', query: { tab: 'drafts' } });
        toast.show('Story world draft deleted', 'nonative', 'primary');
        this.clearAll();
      } catch (e: any) {
        toast.show('Encountered an error while deleting story world draft.', 'nonative', 'danger');
      } finally {
        this.setAutosaveEnabled(false);
      }
    },
  },
  getters: {
    storyworld(): WorldsInfo | null {
      return this.currentStoryWorld;
    },
    loading(): boolean {
      return this.isLoading;
    },
    worldColors(): any[] {
      const storedColorsArray = this.storyworld?.extra.colors || [];
      return storedColorsArray;
    },
    worldColorsEditable(): KeyedColor[] {
      return this.keyedColors || [];
    },
    dropdownCollapsed(): boolean {
      return this.isDropDownCollapsed;
    },
    worldAbout(): any[] {
      /*
        Pre-processing for "about"
      */
      const about = this.storyworld?.extra.about || [];

      about.forEach((row: any) => {
        row.id = row.id || nanoid(8);
        row.isCustom = !flatten(Object.values(worldAboutKeys)).includes(row.key) || false;
        return row;
      });
      return about as any[];
    },
    autosaveEnabled(): boolean {
      return this._autosaveEnabled;
    },
  },
});

export const storyWorldCreatorStore = () => {
  const store = useStoryWorld();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
